.project-report {
  .content-gap {
    &__table {
      height: 365px;
      overflow: auto;
    }
  }
  
  .keyword-tracking {
    &__table {
      // height: 240px;
      height: 272px;
      overflow: auto;
    }
  }

  position: relative;
}

/* Override Metronic form switch styling to ensure the circle is visible */
.form-check.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
  
  &:checked {
    background-color: var(--bs-success);
    border-color: var(--bs-success);
    background-position: right center;
  }
  
  &:not(:checked) {
    background-color: #ebedf3;
    border-color: #d7d7d7;
    background-position: left center;
  }
}

.floating-dev-trial-toggle {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  
  .card {
    background-color: rgba(255, 255, 255, 0.95);
    border: 2px solid #ffc107;
    
    .card-body {
      padding: 0.5rem 1rem;
    }
    
    .form-check-input:checked {
      background-color: #ffc107;
      border-color: #ffc107;
    }
    
    label {
      color: #000;
      font-size: 0.9rem;
    }
  }
}

.floating-upgrade-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 100;
  animation: pulse 2s infinite;
  
  .btn {
    padding: 0.75rem 1.5rem;
    border-radius: 2rem;
    box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease;
    
    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 0.75rem 2rem rgba(0, 0, 0, 0.2);
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.4);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(255, 193, 7, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0);
  }
}