.c-app {
  --primary-color: #0caad4;
  --danger-color: #F52020;
  --success-color: #04DE92;
  --info-color: #39f;
  --warning-color: #f9b115;
  --light-color: #ebedef;
  --dark-color: #767676;
  --secondary-color: #FE6C00;
  --black: #000;
  --black-color: #73818F;
  --yellow-color: #FFC43A;
  --yellow-1-color: #FFE600;
  --yellow-2-color: #f57d20;
  --white-color: #FFFFFF;
  --blue-color: #5A6BFF;
  --red-color: #FE0000;
  --gray-color: #ced2d8;
  --gray-1-color: #E5E5E5;
  --gray-2-color: #e1e1e1;
  --gray-3-color: #aaa;
  --blue-1-color: #2EA2CC;
  --blue-2-color: #298EB3;
  --black-1-color: #73818F;
  --black-2-color: #4e4e4e;
  --green-color: #27D9A7;
  --font-family-title: 'Roboto Condensed', sans-serif;
  --font-family-text: 'Roboto', sans-serif;
}

html {
  scroll-behavior: smooth;
}

// .text-primary {
//   color: var(--primary-color) !important;
// }

// #root {
//   width: 100%;
//   position: relative;
// }

// .c-main {
//   padding-top: 30px;
// }

// .register-page,
// .login-page {
//   & #root {
//     &>div.content {
//       height: 100%;
//       display: flex;
//       place-items: center;
//       place-content: center;
//       padding-bottom: 50px;
//     }
//   }
// }

* {
  outline: none;
}

// a {
//   color: var(--primary-color);

//   &:hover {
//     text-decoration: none;
//     color: #f57d20;
//   }
// }

img {
  max-width: 100%;
}

// iframe {
//   z-index: -1 !important;
// }

// .btn {
//   &.btn-secondary {
//     background-color: #f57d20;
//     color: #fff;
//   }

//   &.btn-dark {
//     background-color: #B5B5B5;
//     color: #fff;
//   }

//   &.btn-primary,
//   &.btn-info,
//   &.btn-success {
//     background-color: var(--primary-color);
//     border-color: var(--primary-color);
//     color: #fff;

//     &:focus {
//       background-color: var(--primary-color);
//       border-color: var(--primary-color);
//       color: #fff !important;
//     }

//     &:not(:disabled):not(.disabled):active,
//     &:hover {
//       background-color: #f57d20;
//       color: #fff !important;
//     }

//     .disabled,
//     &:disabled {
//       background-color: var(--primary-color);
//       cursor: not-allowed;
//     }
//   }

//   .c-icon,
//   i {
//     height: .875rem !important;
//     margin: 0 0 4px 0 !important;
//   }

//   &.btn-sm {

//     .c-icon,
//     i {
//       margin: 0 !important;
//     }
//   }

//   &.btn-paypal {
//     padding: 0 !important;
//     min-width: 200px;

//     &>div {
//       display: flex;
//       padding: 0 !important;
//     }
//   }
// }

.page-item {
  .page-link {
    color: var(--primary-color);
    color: #fff;

    &:hover {
      color: #f57d20;
      color: #fff !important;
    }
  }

  &.active {
    .page-link {
      background-color: var(--primary-color);
      border-color: var(--primary-color);

      &:hover {
        color: #fff;
      }
    }
  }
}

// .bg-primary {
//   background-color: var(--primary-color) !important;
// }

.limit-text-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.spinner-border.spinner-border-sm.text-primary {
  color: #fff !important;
}

// .modal {
//   .modal-info {
//     .modal-header {
//       background-color: var(--primary-color);
//     }
//   }

//   .modal-header {
//     .btn-close {
//       background: transparent var(--cui-btn-close-bg, url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='rgba(44, 56, 74, 0.95)' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E")) center/1em auto no-repeat;
//       border: 0;
//       border-radius: .25rem;
//       box-sizing: content-box;
//       height: 1em;
//       opacity: .5;
//       padding: .25em;
//       width: 1em;
//       box-shadow: none !important;
//     }
//   }
// }

.customTabs {
  &.nav {
    .nav-item {
      .nav-link {
        &.active {
          background-color: var(--primary-color);
          color: #fff;
        }
      }

      &[disabled] {
        pointer-events: none;

        .nav-link {
          background-color: #ebedef;
          color: #999;
        }
      }
    }
  }
}

.show-icon-hover {
  .icon {
    margin: 0 3px;
    opacity: 0;
    transition: .3s;
  }

  &:hover {
    .icon {
      opacity: 1;
    }

    button[disabled] {
      .icon {
        opacity: 0;
      }
    }
  }
}

.pagination {
  .page-item {
    .page-link {
      color: #555;

      &:hover {
        color: #555 !important;
        font-weight: bold !important;
      }
    }

    &.active {
      .page-link {
        // color: #fff;
        font-weight: bold;
        background-color: #F1FAFF;
      }
    }
  }
}

select:disabled {
  opacity: 0.4;
}

// .card {
//   &.custom-card {
//     // border-radius: 0;
//     box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25) !important;
//     overflow: hidden;
//     margin-bottom: 30px;

//     &.card-info {
//       background-color: #fff !important;

//       &>.card-header {
//         background-color: var(--primary-color);
//         color: #fff;
//         padding: .40rem 1.25rem;
//         min-height: 56px;
//         display: flex;
//         place-items: center;
//         place-content: space-between;

//         // border-radius: 0;
//         .btn {

//           &.btn-default,
//           &.btn-info {
//             background-color: #fff;
//             color: var(--primary-color) !important;
//           }

//           &.btn-warning-custom {
//             background: #FE6C00;
//             border: 0.5px solid var(--primary-color);
//             box-sizing: border-box;
//             border-radius: 3px;
//             color: #fff !important;
//           }

//           &.btn-success-custom {
//             background: #04DE92;
//             border: 0.5px solid var(--primary-color);
//             box-sizing: border-box;
//             border-radius: 3px;
//             color: #fff !important;
//           }
//         }
//       }

//       .card-body {
//         background-color: #fff !important;

//         // border-radius: 0;
//         .btn {

//           &.btn-default,
//           &.btn-info {
//             background-color: #fff;
//             color: var(--primary-color) !important;
//           }

//           &.btn-warning-custom {
//             background: #FE6C00;
//             border: 0.5px solid var(--primary-color);
//             box-sizing: border-box;
//             border-radius: 3px;
//             color: #fff !important;
//           }

//           &.btn-success-custom {
//             background: #04DE92;
//             border: 0.5px solid var(--primary-color);
//             box-sizing: border-box;
//             border-radius: 3px;
//             color: #fff !important;
//           }
//         }
//       }
//     }

//     &.card-info-all {
//       background-color: var(--primary-color) !important;
//     }
//   }
// }

// .container {
//   padding-left: 0;
//   padding-right: 0;

//   @media (min-width: 1400px) {

//     &.container,
//     &.container-lg,
//     &.container-md,
//     &.container-sm,
//     &.container-xl,
//     &.container-xxl {
//       max-width: 1180px;
//     }
//   }
// }

.dropzone {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: rgb(238, 238, 238);
  border-style: dashed;
  background-color: rgb(250, 250, 250);
  color: rgb(189, 189, 189);
  outline: none;
  transition: border 0.24s ease-in-out 0s;
  min-height: 200px;
  place-content: center;
  margin-bottom: 15px;
  font-size: 18px;

  min-height: 0;
  padding: 9px 0;
  border: 0 none;
  background-color: #04de92;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }


  // &:hover {
  //   cursor: pointer;
  // }

  .c-icon {
    width: 30px !important;
    height: 30px !important;
    margin-right: 10px !important;
  }

  p {
    margin: 0;
  }
}

.input-group-text {
  border-radius: 0;
  background: #fff !important;
}

.table {
  th {
    vertical-align: top;
    padding: 0.75rem !important;

    &:first-child {
      padding-left: 0 !important;
    }
  }
}



.lesson-list,
.lesson-golfswing-list {
  color: #73818F;

  .input-group {
    max-width: 50%;

    .btn-search {
      background: #fff !important;
      color: #07A8D2 !important;
    }
  }

  .photo {
    background: #07A8D2;
    border-radius: 50%;
    display: flex;
    width: fit-content;
    margin: auto;

    img {
      width: 50px;
      height: 50px;
      padding: 10px;
    }
  }

  .table {
    margin-bottom: 0;

    th,
    td {
      vertical-align: middle;
      border: none;
      padding: 15px 10px;
    }
  }

  &.lesson-golfswing-list {
    .table {
      margin-bottom: 0;

      th,
      td {
        vertical-align: middle;
        border: none;
        padding: 5px;
      }
    }

    .photo {
      background: #04DE92;
    }
  }

  &.has-border {
    tr {
      border-top: 1px solid #F8F8F8;

      &:last-child {
        border-bottom: 1px solid #F8F8F8;
      }
    }
  }

  .lesson-list-item,
  .lesson-golfswing-list-item {
    .title {
      display: block;
      font-size: 110%;
      font-weight: bold;
      margin-bottom: 4px;
      color: #73818F;
    }

    .description {
      font-size: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-height: 16px;
      /* fallback */
      max-height: 32px;
      /* fallback */
      -webkit-line-clamp: 1;
      /* number of lines to show */
      -webkit-box-orient: vertical;
      margin-bottom: 5px;
      color: #73818F;
      ;

      strong {
        font-weight: normal;
      }

      p {
        margin-bottom: 0;
        color: #73818F !important;
      }

      video,
      img {
        display: none;
      }

      span {
        color: #73818F !important;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      strong {
        font-size: .875rem !important;
        font-weight: normal !important;

      }
    }

    .category {
      color: #73818F;
      font-weight: normal;
      display: flex;
      place-items: center;
      padding-top: 2px;

      span,
      div {
        display: flex;
        place-items: center;
      }

      &>div {
        margin-right: 20px;
      }

      img {
        margin-right: 5px;
      }

      span {
        margin-right: 5px;
      }
    }
  }
}

.image-preview,
.video-preview {
  position: relative;
  overflow: hidden;
  -webkit-transition: .3s;
  transition: .3s;
  background: #000;
  padding-top: 75%;
  border-radius: 5px;

  .lazy-load-image-background {
    width: 100%;
    object-fit: contain;
    margin: auto;
    background: #000;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);

    img {
      height: 100%;
      width: 100%;
    }
  }

  .action {
    position: absolute;
    right: 15px;
    top: 15px;
  }
}

.reviews-section {

  .review {
    padding: 15px 0;
    font-family: var(--font-family-text);

    h3 {
      color: var(--blue-1-color);
      text-align: center;
      margin-top: 0;
      font-weight: bold;
      font-size: 30px;
      line-height: 35px;
      margin-bottom: 20px;
      font-family: var(--font-family-title);

    }

    .review-items {
      &__item {
        h3 {
          color: var(--blue-1-color);
          text-align: center;
          margin-top: 0;
          font-weight: bold;
          font-size: 30px;
          line-height: 35px;
          margin-bottom: 20px;
        }

        &__author {
          margin-bottom: 15px;
          font-size: 20px;
          font-weight: bold;
          font-style: italic;
          color: var(--black-color);
        }

        &__content {
          font-size: 20px;
          line-height: 23px;
          color: var(--black-color);
          font-style: italic;
        }

        &__rate {
          margin: 20px 0 15px 0;
          text-align: center;

          svg {
            fill: var(--gray-color);
            width: 25px;
            height: 25px;
            margin: 0 5px;

            &.active {
              fill: var(--yellow-1-color);
            }
          }
        }
      }
    }

    .carousel-indicators {
      position: initial;
      margin-bottom: -10px;

      li {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: var(--gray-color);
        margin: 0 5px;
      }
    }

    .carousel-control-prev {
      left: -40px;
      width: auto;
      top: -30px;
      cursor: pointer;

      span {
        background-image: url("./Assets/icons/prev.svg");
      }
    }

    .carousel-control-next {
      right: -40px;
      width: auto;
      top: -30px;
      cursor: pointer;

      span {
        background-image: url("./Assets/icons/next.svg");
      }
    }
  }
}

.form-control {
  &[type="checkbox"] {
    display: initial;
    width: auto;
    height: auto;
    margin-left: 15px;
  }
}

.custom-tab {
  font-family: var(--font-family-text);

  .nav {
    padding: 0 !important;
    // border-bottom: none;
    margin: 0 -1px;
    align-items: stretch;
    gap: 5px;
    background-color: var(--light);

    .nav-item {
      // flex: 1 1;

      .nav-link {
        height: 100%;
        padding: 10px 20px;
        font-weight: bold;
        font-size: 20px;
        line-height: 23px;
        color: var(--blue-1-color);
        background-color: #ebedef4d;
        border: 0;
        box-shadow: 0px 0px 3px #bbb;
        margin: 0 1px;
        border-radius: 0.25rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        font-family: var(--font-family-title);
        border: 1px dashed var(--gray-2-color);
        border-bottom: none;

        &.active {
          background-color: #fff;
          border-bottom-color: #fff;
          border-color: var(--gray-color);
          color: var(--black-2-color);
          box-shadow: 0px -3px 3px #bbb;
          border-color: transparent;
        }
      }

      &[disabled] {
        pointer-events: none;

        .nav-link {
          background-color: #ebedef;
          color: #999;
        }
      }
    }
  }

  .tab-content {
    padding: 0;
    // overflow: hidden;
    // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25) !important;
    // background-color: #fff;
    // border-radius: 0.25rem;
    // border-top-left-radius: 0;
    // border-top-right-radius: 0;
  }
}


.custom-widget {
  .progress {
    display: none !important;
  }

  .h1 {
    font-size: 150%;
    color: #fff !important;
    opacity: 1;
  }

  .h4 {
    text-align: right;
    font-weight: bold;
    font-size: 170%;
  }

  small {
    text-align: right !important;
    display: block;
    margin-top: 5px;
    text-transform: capitalize !important;
    font-size: 100% !important;
    font-weight: 500 !important;
  }
}

@media only screen and (max-width: 768px) {

  .lesson-list,
  .lesson-golfswing-list {

    .input-group {
      max-width: 50%;
    }

    .photo {
      background: #07A8D2;
      border-radius: 50%;
      display: flex;
      width: fit-content;
      margin: auto;

      img {
        width: 50px;
        height: 50px;
        padding: 10px;
      }
    }

    .table {
      margin-bottom: 0;
      min-width: 400px;
      overflow: auto;

      th,
      td {
        vertical-align: middle;
        border: none;
        padding: 15px 10px;
      }
    }

    &.lesson-golfswing-list {
      .table {
        margin-bottom: 0;

        th,
        td {
          vertical-align: middle;
          border: none;
          padding: 5px;
        }
      }

      .photo {
        background: #04DE92;
      }
    }

    &.has-border {
      tr {
        border-top: 1px solid #F8F8F8;

        &:last-child {
          border-bottom: 1px solid #F8F8F8;
        }
      }
    }

    .lesson-list-item,
    .lesson-golfswing-list-item {
      .title {
        font-size: 110%;
        margin-bottom: 4px;
      }

      .description {
        line-height: 16px;
        max-height: 32px;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin-bottom: 5px;
      }

      .category {
        color: #73818F;
        place-items: flex-start;
        flex-direction: column;
        padding-top: 0;

        span,
        div {
          display: flex;
          place-items: center;
        }

        &>div {
          margin-right: 20px;
          margin-top: 2px;
        }

        img {
          margin-right: 5px;
        }

        span {
          margin-right: 5px;
        }
      }
    }
  }

  .reviews-section {
    margin-left: 0;
    margin-right: 0;

    &>div {
      padding-left: 12px;
      padding-right: 12px;
    }

    .review {

      .review-items {
        &__item {
          h3 {
            font-size: 30px;
            line-height: 35px;
            margin-bottom: 20px;
          }

          &__author {
            margin-bottom: 15px;
            font-size: 20px;
          }

          &__content {
            font-size: 16px;
            padding: 0 15px;
          }

          &__rate {
            margin: 15px 0;

            svg {
              fill: var(--gray-color);

              &.active {
                fill: var(--yellow-1-color);
              }
            }
          }
        }
      }

      .carousel-control-prev {
        left: -20px;
      }

      .carousel-control-next {
        right: -20px;
      }
    }
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid\9;
}

.react-bootstrap-table th .order>.dropup>.caret,
.react-bootstrap-table th .order>.dropdown>.caret {
  color: #aaa;
}

.table tr:first-child,
.table th:first-child,
.table td:first-child {
  padding-left: 0.75rem !important;
}

.table {
  .selection-cell,
  .selection-cell-header {
    vertical-align: bottom;
    input {
      cursor: pointer;
    }
  }

  .has-select {
    tr {
      &:hover {
        cursor: pointer;

        td {
          color: #333 !important;
        }
      }
    }
  }
}

// table {
//   th {
//     .order {
//       display: none;
//     }
//   }
// }

.react-bootstrap-table-pagination {
  margin-left: 0;
  margin-right: 0;
}

.react-bootstrap-table-pagination-list-hidden {
  display: none;
}
