.tags-input {
  .react-tagsinput-tag {
    background-color: #F1FAFF;
    border-color: #009EF7;
    color: #009EF7;
    border-radius: 4px;
  }

  .react-tagsinput-input {
    margin-bottom: 0;
  }
}

.tags-input {
  // min-height: 300px;
  & > span {
    margin-top: 10px;
    display: block;
  }

  .react-tagsinput-tag {
    background-color: #F1FAFF;
    border-color: #009EF7;
    color: #009EF7;
    border-radius: 4px;
  }

  .react-tagsinput-input {
    margin-bottom: 0;
  }
}

.react-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
}

.react-tagsinput--focused {
  border-color: #a5d24a;
}

.react-tagsinput-tag {
  background-color: #cde69c;
  border-radius: 2px;
  border: 1px solid #a5d24a;
  color: #638421;
  display: inline-block;
  // font-family: sans-serif;
  // font-size: 13px;
  // font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: " ×";
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  // font-family: sans-serif;
  // font-size: 13px;
  // font-weight: 400;
  margin-bottom: 6px;
  margin-top: 15px;
  outline: none;
  padding: 5px;
  // width: 80px;
}

.keyword-input-container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  
  textarea {
    flex: 1;
    resize: vertical;
  }
  
  .keyword-add-btn {
    margin-left: 10px;
    margin-top: 5px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    
    @media (max-width: 767px) {
      padding: 0.5rem 1rem;
    }
  }
}

// Responsive adjustments
@media (max-width: 767px) {
  .keyword-input-container {
    flex-direction: column;
    
    .keyword-add-btn {
      margin-left: 0;
      margin-top: 10px;
      align-self: flex-end;
    }
  }
}
